

.box{
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 600px;
    height: 80vh;
}
.heading{
    display: flex;
    flex-direction: column;
    align-items: center;
  margin: 2rem;
}
.heading h1{
    color: #2A00B7;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 14px;
}
.underline{
    width: 61px;
    height: 6px;
    border-radius: 9px;
    background: #2A00B7; ;
}
.inputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.input{
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 10px;
    height: 80px;
    width: 480px;
}
.input img{
    margin: 0 30px;
}
.input input{
    height:50px;
    width: 400px;
    outline: none;
    border: none;
    background-color: transparent;
    color:#797979;
    font-size: larger;
}
.forgetpass{
margin: 1.5rem 4rem;
}
.forgetpass>:nth-child(1){
    font-size: 1.3rem;
    color: #2A00B7;
}
.forgetpass>:nth-child(2){
    font-size: 1.1rem;
     cursor: pointer;
}
.submit-container{
    display: flex;
    gap: 30px;
    margin: 5px auto;
}
.submit-margin{
    display: flex;
    gap: 30px;
    margin: 2rem auto;
}
.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 50px;
    color: white;
    background-color: #4c00b4;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}
.gray{
    background-color: #EAEAEA;
    color: #676767;
}